import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setCurrentDevice, setCurrentSite, setIsLogged, setSiteDetails, setUserDetails } from "../../redux/userSlice";
import Dropdown from "react-bootstrap/Dropdown";
import "./Layout.css";
import Swal from "sweetalert2";
import { userRole } from "../../constant";

const Navbar = ({ handle }) => {
  const ref = useRef();
  const ref1 = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ToggleNav, setToggleNav] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [NotificationDropdownIsOpen, setNotificationDropdownIsOpen] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);
  const toggleNotificationDropdownIsOpen = () => setNotificationDropdownIsOpen(!NotificationDropdownIsOpen);

  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get(`${process.env.REACT_APP_API_URL}/user/logout`, { withCredentials: true }).then((res) => {
          dispatch(setIsLogged(false));
          dispatch(setUserDetails({}));
          dispatch(setCurrentSite({}));
          dispatch(setSiteDetails({}));
          dispatch(setCurrentDevice({}));
          window.localStorage.clear();
          navigate("/");
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropdownIsOpen && ref.current && !ref.current.contains(e.target)) {
        setDropdownIsOpen(false);
      }
      if (NotificationDropdownIsOpen && ref1.current && !ref1.current.contains(e.target)) {
        setNotificationDropdownIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdownIsOpen, NotificationDropdownIsOpen]);
  return (
    <nav className="navbar navbar-expand-lg navbar-bg p-0 ">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          onClick={(e) => setToggleNav(!ToggleNav)}
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={ToggleNav ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav top-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link ">
                Main
              </NavLink>
            </li>
            {[userRole.superAdmin, userRole.admin].includes(userDetails.role) && (
              <>
                <li className="nav-item">
                  {userDetails?.role === userRole.superAdmin ? (
                    <NavLink to="/users" className="nav-link">
                      Users
                    </NavLink>
                  ) : (
                    <NavLink to={"/users?role=installer"} className="nav-link">
                      Users
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  <NavLink to="/site-locations" className="nav-link">
                    Site Locations
                  </NavLink>
                </li>
              </>
            )}
            {/* {(() => {
              switch (userDetails.role) {
                case "superAdmin":
                  return (
                    <li className="nav-item dropdown">
                      <Dropdown show={dropdownIsOpen}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="bg-transparent text-dark border-0 fw-semibold"
                          onClick={(e) => toggleDropdown()}
                        >
                          Users
                        </Dropdown.Toggle>
                        <Dropdown.Menu onClick={(e) => toggleDropdown()} ref={ref}>
                          <NavLink to="/users" className="dropdown-item">
                            Manage All Users
                          </NavLink>
                          <NavLink to="/installers" className="dropdown-item">
                            Manage Installers
                          </NavLink>
                          <NavLink to="/admins" className="dropdown-item">
                            Manage Admins
                          </NavLink>
                          <NavLink to="/site-users" className="dropdown-item">
                            Manage Site Users
                          </NavLink>
                          <NavLink to="/public-users" className="dropdown-item">
                            Manage Public Users
                          </NavLink>
                          <NavLink to="/site-locations" className="dropdown-item">
                            Manage Locations
                          </NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );

                case "admin":
                  return (
                    <li className="nav-item dropdown">
                      <Dropdown show={dropdownIsOpen}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="bg-transparent text-dark border-0 fw-semibold"
                          onClick={(e) => toggleDropdown()}
                        >
                          Users
                        </Dropdown.Toggle>
                        <Dropdown.Menu onClick={(e) => toggleDropdown()} ref={ref}>
                          <NavLink to="/users" className="dropdown-item">
                            Manage All Users
                          </NavLink>
                          <NavLink to="/installers" className="dropdown-item">
                            Manage Installers
                          </NavLink>
                          <NavLink to="/site-locations" className="dropdown-item">
                            Manage Site Locations
                          </NavLink>
                          <NavLink to="/site-users" className="dropdown-item">
                            Manage Site Users
                          </NavLink>
                          <NavLink to="/public-users" className="dropdown-item">
                            Manage Public Users
                          </NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );
                case "user":
                  return;
                case "public":
                  return;
                default:
                  return;
              }
            })()} */}
            <li className="nav-item">
              <NavLink to="/devices" className="nav-link">
                Devices
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/analysis-reporting" className="nav-link">
                Analysis &amp; Reporting
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/settings" className="nav-link">
                Settings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="nav-link">
                Profile
              </NavLink>
            </li>
            {userDetails.role !== "public" && (
              <li className="nav-item">
                <NavLink to="/alarm-summary" className="nav-link">
                  Notifications
                </NavLink>
              </li>
            )}
            {/* {(() => {
              switch (userDetails.role) {
                case "public":
                  return;
                  break;
                default:
                  return (
                    <li className="nav-item dropdown">
                      <Dropdown show={NotificationDropdownIsOpen}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="bg-transparent text-dark border-0 fw-semibold"
                          onClick={(e) => toggleNotificationDropdownIsOpen()}
                        >
                          Notification
                        </Dropdown.Toggle>
                        <Dropdown.Menu onClick={(e) => toggleNotificationDropdownIsOpen()} ref={ref1}>
                          <NavLink to="/alarm-summary" className="dropdown-item">
                            System Alarm Summary
                          </NavLink>
                          <NavLink to="/create-alarm" className="dropdown-item">
                            Create New Alarm
                          </NavLink>
                          <NavLink to="/recipient-list" className="dropdown-item">
                            Notification Recipient List
                          </NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );
              }
            })()} */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
