import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AnalysisReportingSidebar from "../../components/AnalysisReporting/AnalysisReportingSidebar";
import ReportTable from "../../components/Reports/ReportTable";
import Select from "react-select";
import SplineChart from "../../components/Charts/SplineChart";
import { useMemo } from "react";

import { api, endpoint } from "../../api";
import { exportCSV } from "../../utils/exportCsv";
import { DatePicker } from "antd";

const AnalysisReporting = () => {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [reportTypeGraph, setReportTypeGraph] = useState(true);
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState();
  const [sites, setSites] = useState([]);
  const [graphFrom, setGraphFrom] = useState();
  const [graphTo, setGraphTo] = useState();
  const [state, setState] = useState({
    interval: "1",
    device: "",
    from: "",
    to: "",
    operator: "",
    parameters: "",
  });

  const { interval, device, from, to, parameters } = state;

  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item._id, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleParameterChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const getDeviceParameters = async (deviceId) => {
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (device) {
      setState({ ...state, parameters: [] });

      getDeviceParameters(device);
    }
  }, [device]);

  const onChangeSite = async (locationId) => {
    if (locationId) {
      setState({ ...state, device: "" });
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      if (response) {
        setDevices(response.data?.results);
      }
    }
  };

  const getSiteLocationList = async () => {
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/site-location/my-site`, {
        withCredentials: true,
      });
      setSites(data?.data);
    } catch (error) {}
  };

  /**
   * @param { String } type
   * @type graph | table
   */
  const getReport = async (type) => {
    if (type === "graph") {
      setIsLoadingGraph(true);
      setGraphFrom(from);
      setGraphTo(to);
    } else {
      setIsLoadingTable(true);
    }
    const body = { ...state };
    if (state.parameters?.length) body.parameters = state.parameters.map((p) => p.label);
    try {
      const response = await api.post(endpoint.parameter_comparison, body, {
        params: { limit, page, type },
      });
      if (type === "graph") {
        setDeviceData(response);
      } else {
        setDeviceTableData(response?.results);
        setTotal(response?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
    if (type === "graph") {
      setIsLoadingGraph(false);
    } else {
      setIsLoadingTable(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    getReport("graph");
    getReport("table");
  };

  const handleDownloadReport = () => {
    const device = devices.find((d) => d._id === state.device);
    exportCSV({
      data: deviceTableData.map(({ _id, date, ...e }) => {
        return {
          Date: date,
          Device: device.apiKey,
          ...e,
        };
      }),
      filename: `report-parameter-comparison-${device.apiKey}`,
    });
  };

  useEffect(() => {
    getSiteLocationList();
    document.title = "SEDA - Parameter Comparison";
  }, []);

  console.log(state);

  return (
    <div className="analysis">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <AnalysisReportingSidebar />
          </div>
          <div className="col-md-10">
            <div className="card p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <h4>Device Parameter Comparison</h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  {deviceTableData.length > 0 && (
                    <button className="btn btn-danger me-2" onClick={handleDownloadReport}>
                      Download Report
                    </button>
                  )}
                  <Link to="/" className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <button
                    className={`btn me-2 ${reportTypeGraph ? " btn-info" : "btn-success"}`}
                    onClick={() => setReportTypeGraph(true)}
                  >
                    Graph
                  </button>
                  <button
                    className={`btn ${!reportTypeGraph ? " btn-info" : "btn-success"}`}
                    onClick={() => setReportTypeGraph(false)}
                  >
                    Table
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-3 mt-2">
                    <select className="form-select" name="interval" defaultValue={interval} onChange={onInputChange}>
                      <option value="1">Default</option>
                      <option value="30">30 Min</option>
                      <option value="60">1 Hour</option>
                      <option value="1440">24 Hours</option>
                    </select>
                  </div>
                  <div className="col-md-3 mt-2">
                    <select
                      className="form-select"
                      name="siteLocations"
                      onChange={(e) => onChangeSite(e.target.value)}
                      aria-label="Select an admin"
                      defaultValue={""}
                    >
                      <option disabled label="Select Site" value={""}></option>
                      {sites?.length &&
                        sites.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 mt-2">
                    <select className="form-select" name="device" value={state.device} onChange={onInputChange}>
                      <option>Select Device</option>
                      {devices &&
                        devices.length > 0 &&
                        devices.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 mt-2">
                    <select className="form-select" name="operator" value={state.operator} onChange={onInputChange}>
                      <option>Select Operator</option>
                      <option value="max">Maximum</option>
                      <option value="min">Minimum</option>
                      <option value="avg">Average</option>
                      <option value="sum">Sum</option>
                    </select>
                  </div>
                  <div className="col-md-3 mt-2">
                    <DatePicker
                      className="form-control"
                      placeholder="Select Start Date"
                      size="large"
                      onChange={(e, date) => {
                        setState({ ...state, from: date });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mt-2">
                    <DatePicker
                      className="form-control"
                      placeholder="Select End Date"
                      size="large"
                      onChange={(e, date) => {
                        setState({ ...state, to: date });
                      }}
                    />
                  </div>

                  <div className="col-md-3 mt-2">
                    <Select
                      isMulti
                      value={parameters}
                      onChange={(value) => handleParameterChange("parameters", value)}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder={`Select Device Parameter`}
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-4 " style={{ gap: 10 }}>
                    <button className="btn btn-success" onClick={handleSubmit}>
                      Generate
                    </button>
                  </div>
                </div>
              </form>

              {SuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {SuccessMessage}{" "}
                </div>
              )}

              <div className="row" style={{ minHeight: "400px" }}>
                <div className="col-md-12">
                  <div className="mt-3">
                    {reportTypeGraph ? (
                      <SplineChart
                        // title="Parameter Comparison"
                        data={deviceData}
                        from={graphFrom}
                        to={graphTo}
                        isLoading={isLoadingGraph}
                      />
                    ) : (
                      <ReportTable
                        title="Parameter Comparison"
                        data={deviceTableData}
                        parameters={parameters}
                        total={total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                        isLoading={isLoadingTable}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisReporting;
