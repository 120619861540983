import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DeviceParameters from "./DeviceParameters";
import { FiEye } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { AssignedDeviceFormula } from "../../components/Devices";
import { api, endpoint } from "../../api";
import { notification, Spin } from "antd";
import DataTable from "react-data-table-component";
import { ThemeContext } from "../../App";
import { DeviceFormulaOperation } from "./DeviceFormulaOperation";

const DeviceDetails = () => {
  const { isDark } = useContext(ThemeContext);
  const params = useParams();
  const deviceId = params.deviceId;
  const [isDeviceLoading, setDeviceIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isParameterLoading, setIsParameterLoading] = useState(false);
  const [isFormulaLoading, setIsFormulaLoading] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState();
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [systemFormulas, setSystemFormulas] = useState([]);
  const [deviceFormulas, setDeviceFormulas] = useState([]);
  const [selectedFormulaId, setSelectedFormulaId] = useState("");
  const [selectedFormula, setSelectedFormula] = useState();

  const initialState = {
    name: "",
    unit: "",
    formula: "",
    device: deviceId,
    type: "device",
    operations: [],
  };

  const [formulaData, setFormulaData] = useState(initialState);

  function handleChange(value, name) {
    console.log(value, name);
    setFormulaData({
      ...formulaData,
      [name]: value,
    });
  }

  const onChangeOperation = (index, obj) => {
    const data = Array.from(formulaData.operations);

    data[index] = { ...data[index], ...obj };

    setFormulaData({
      ...formulaData,
      operations: data,
    });
  };

  const [SuccessMessage, setSuccessMessage] = useState();

  const onInputChange = (e) => {
    setSelectedFormulaId(e.target.value);
  };

  useEffect(() => {
    if (selectedFormulaId) {
      const systemFormula = systemFormulas.find((item) => String(item._id) === String(selectedFormulaId));
      if (systemFormula) {
        setSelectedFormula(systemFormula);
      }
    } else {
      setSelectedFormula();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormulaId]);

  useEffect(() => {
    if (selectedFormula) {
      setFormulaData({
        ...formulaData,
        name: `${deviceDetails?.name} - ${selectedFormula?.name}`,
        unit: selectedFormula?.unit,
        formula: selectedFormula?.formula,
        operations: selectedFormula.operations,
      });
    }
    // eslint-disable-next-line
  }, [selectedFormula, deviceDetails]);

  const getDevice = async () => {
    setDeviceIsLoading(true);
    try {
      const response = await axios.get(`/device/${deviceId}`, {
        withCredentials: true,
      });
      if (response) {
        setDeviceDetails(response.data.result);
      }
    } catch (error) {}
    setDeviceIsLoading(false);
  };

  const getAllParameters = async () => {
    try {
      const response = await api.get(endpoint.get_device_parameter, {
        params: { deviceId, limit: 100, page: 1 },
      });
      setDeviceParameters(response?.results);
    } catch (error) {}
  };

  
  const getDeviceParameters = async () => {
    setIsParameterLoading(true);
    await getAllParameters();
    setIsParameterLoading(false);
  };

  const getSystemFormulas = async () => {
    const response = await axios.get(`/formula`, { withCredentials: true });
    if (response) {
      setSystemFormulas(response.data);
    }
  };

  const getDeviceFormulas = async () => {
    setIsFormulaLoading(true);
    const response = await axios.get(`/formula/`, {
      withCredentials: true,
      params: { device: deviceId, type: "device" },
    });
    if (response) {
      setDeviceFormulas(response.data);
    }
    setIsFormulaLoading(false);
  };

  useEffect(() => {
    document.title = "Seda - Device Information";
    getDeviceParameters();
    getDevice();
    getSystemFormulas();
    getDeviceFormulas();
    // eslint-disable-next-line
  }, [deviceId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      await axios.post(
        `/formula`,
        { ...formulaData, systemFormula: selectedFormulaId },
        {
          withCredentials: true,
        }
      );
      setSelectedFormulaId("");
      setFormulaData(initialState);
      getDeviceFormulas();
      getDeviceParameters();
      notification.success({ message: "Formula Created Successfully", placement: "bottomLeft" });
      setTimeout(() => {
        setSuccessMessage();
      }, 2000);
    } catch (error) {
      notification.error({ message: error?.response?.data?.message || error.message, placement: "bottomLeft" });
    }
    setIsSubmitLoading(false);
  };

  const deleteDeviceFormula = async (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to unassign formula?",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/formula/` + Id, {
            withCredentials: true,
          })
          .then((res) => {
            getDeviceFormulas();
            getDeviceParameters();
            Swal.fire({
              title: "Done!",
              text: "Formula unassigned successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  useEffect(() => {
    let formula = "";
    for (const op of formulaData.operations) {
      if (["time", "area", "year", "month"].includes(op.value)) {
        formula += " " + op.value[0].toUpperCase() + op.value.slice(1);
      } else {
        formula += " " + op.value;
      }
    }
    setFormulaData({ ...formulaData, formula: formula.trim() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaData.operations]);

  return (
    <Spin spinning={isDeviceLoading}>
      <div className="card p-3 mb-3">
        <h3>Device Information</h3>
        <div className="row mt-4">
          <div className="col-md-6">
            <h4 className="mb-4">General Description</h4>
            <div className="row mb-2">
              <div className="col-3">Device Name</div>
              <div className="col-9">: {deviceDetails?.name}</div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Device Type</div>
              <div className="col-9">: {deviceDetails?.deviceType?.name}</div>
            </div>

            <div className="row mb-2">
              <div className="col-3">Energy Source</div>
              <div className="col-9">: {deviceDetails?.energySource}</div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Main Incoming</div>
              <div className="col-9">: {isDeviceLoading ? "" : deviceDetails?.mainIncoming ? "Yes" : "No"}</div>
            </div>

            {deviceDetails?.deviceType?.name !== "Virtual" ? (
              <>
                <div className="row mb-2">
                  <div className="col-3">Serial No.</div>
                  <div className="col-9">: {deviceDetails?.serial}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">API Key</div>
                  <div className="col-9">: {deviceDetails?.apiKey}</div>
                </div>
              </>
            ) : null}

            <div className="row mb-2">
              <div className="col-3">Site Location</div>
              <div className="col-9">: {deviceDetails?.site?.name}</div>
            </div>
            {/* <div className="row mb-2">
              <div className="col-3">Installer</div>
              <div className="col-9">: {deviceDetails?.site?.installer?.name ?? "-"}</div>
            </div> */}
          </div>
          <div className="col-md-6">
            {deviceDetails?.devices?.length > 0 && (
              <DataTable
                columns={[
                  { name: "Serial No", cell: (_, index) => <div>{index + 1}</div> },
                  { name: "Device Name", selector: (row) => row.name },
                  { name: "API Key", selector: (row) => row.apiKey },
                  {
                    name: "Action",
                    selector: (row) => (
                      <Link to={`/devices/details/` + row._id} className="btn btn-info me-1">
                        <FiEye />
                      </Link>
                    ),
                  },
                ]}
                data={deviceDetails?.devices}
                theme={isDark ? "dark" : "light "}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 d-flex justify-content-end">
            <Link to={`/devices/edit/${deviceDetails?._id}`} className="btn btn-success me-2">
              Edit
            </Link>
            <Link to="/devices" className="btn btn-secondary">
              Back
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="row mb-2">
            <div className="col-md-12">
              <DeviceParameters
                isVirtualDevice={deviceDetails?.deviceType?.name === "Virtual"}
                deviceId={deviceId}
                device={deviceDetails}
                getDevice={getDevice}
                getDeviceParameters={getDeviceParameters}
                isLoading={isParameterLoading}
              />
            </div>
            <div className="col-md-12">
              <AssignedDeviceFormula
                deviceFormulas={deviceFormulas}
                deleteFormula={deleteDeviceFormula}
                deviceParameters={deviceParameters}
                refetchFormula={getDeviceFormulas}
                isLoading={isFormulaLoading}
              />
            </div>
          </div>
          <div className="row mt-5">
            <h4>Assign Formula to Device</h4>
            <div className="d-flex justify-content-center">
              {isSubmitLoading && <Spinner animation="border" variant="dark" />}
            </div>
            {SuccessMessage && (
              <div className="alert alert-success" role="alert">
                {SuccessMessage}{" "}
              </div>
            )}
            <div className="row me-3">
              <div className="col-md-4">
                <label htmlFor="formula">Assign Formula</label>
                <select
                  name="formulaList"
                  id="formula"
                  className="form-select"
                  value={selectedFormulaId}
                  onChange={onInputChange}
                >
                  <option value="">Select a formula</option>
                  {systemFormulas &&
                    systemFormulas.length > 0 &&
                    systemFormulas.map((item, index) => (
                      <option value={item?._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="formula">Formula Preview</label>
                <input type="text" className="form-control" value={formulaData.formula} disabled />
              </div>
              <div className="col-md-4">
                <label htmlFor="formula">Formula Unit/Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={formulaData?.unit}
                  onChange={(e) => handleChange(e.target.value, "unit")}
                  disabled
                />
              </div>
            </div>
            <div>
              <div style={{ display: "flex", flex: 1, overflowX: "auto", paddingTop: 15, paddingBottom: 15 }}>
                <div
                  className=" gap-3"
                  style={{
                    display: "flex",
                  }}
                >
                  {Array.isArray(selectedFormula?.operations) &&
                    selectedFormula.operations.map((op, index) => {
                      const parameters = Array.isArray(deviceParameters)
                        ? deviceParameters.map((p) => ({
                            name: `${p.name} (${p?.device?.name})`,
                            value: `${p.name} (${p?.device?.name})`,
                            _id: p._id,
                          }))
                        : [];
                      return (
                        <DeviceFormulaOperation
                          key={index}
                          operation={op}
                          parameters={parameters}
                          formulas={deviceFormulas}
                          onChange={(obj) => onChangeOperation(index, obj)}
                          disabled={!["parameter", "formula"].includes(op.option)}
                          width={["parameter", "formula"].includes(op.option) ? "200px" : "150px"}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="row">
                <div className="col-md-12">
                  <b>Device Formula Preview: {formulaData.formula}</b>
                </div>
              </div>
              <div className="row mt-2 mb-3">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="name">Formula Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formulaData.name}
                        onChange={(e) => handleChange(e.target.value, "name")}
                        className="form-control"
                        placeholder="Enter formula name"
                        required
                      />
                    </div>
                    <div>
                      <button className="btn btn-success me-2" type="submit">
                        Save Formula
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DeviceDetails;
