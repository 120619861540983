import React, { useState, useEffect } from "react";
import { Tag, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Select, Spin } from "antd";
import { validateFields } from "../../utils/validation";
import { endpoint } from "../../api";
import DataTable from "react-data-table-component";
import moment from "moment";
import { TiInfoLarge } from "react-icons/ti";

const formSchema = {
  name: {
    type: "string",
    required: true,
  },
  deviceType: {
    type: "object",
    required: true,
  },
  site: {
    type: "string",
    required: true,
  },
};

const getFormSchema = (deviceType) => {
  let schema = {};
  if (deviceType !== "Virtual") {
    schema = {
      ...formSchema,
      energySource: {
        type: "string",
        required: true,
      },
      mainIncoming: {
        type: "string",
        required: true,
      },
      serial: {
        type: "string",
        required: true,
      },
      apiKey: {
        type: "string",
        required: true,
      },
    };
  } else {
    schema = {
      ...formSchema,
      devices: {
        type: "array",
        required: false,
        minLength: 1,
      },
    };
  }

  return schema;
};

const DeviceForm = ({ mode, data }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [devices, setDevices] = useState([]);
  const [siteLocations, setSiteLocations] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [error, setError] = useState({});
  const [state, setState] = useState({
    name: "",
    deviceType: undefined,
    site: "",
    energySource: "",
    mainIncoming: "",
    serial: "",
    apiKey: "",
    devices: [],
  });

  const { name, deviceType, serial, apiKey, site, mainIncoming, energySource } = state;

  const onSelectDevice = (e) => {
    const data = Array.from(state.devices);
    const deviceId = e.target.value;
    if (data.includes(deviceId)) {
      data.splice(data.indexOf(deviceId), 1);
    } else {
      data.push(deviceId);
    }
    setState({ ...state, devices: data });
  };

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const createDevice = async (values) => {
    setIsLoading(true);

    try {
      await axios.post(endpoint.get_devices, values, { withCredentials: true });
      setIsLoading(false);
      setSuccessMessage("New device created successfully");
      setTimeout(() => {
        setSuccessMessage("");
        navigate(-1);
      }, 2000);
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setErrorMessage(error.response.data?.message || "Something went wrong!");
        setTimeout(() => {
          // setErrorMessage();
        }, 2000);
      }
    }
  };

  const updateDevice = async (values) => {
    try {
      await axios.put(endpoint.get_devices + "/" + data?._id, values, { withCredentials: true });
      setIsLoading(false);
      setSuccessMessage("Device updated successfully");
      setTimeout(() => {
        setSuccessMessage("");
        navigate(-1);
      }, 2000);
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setErrorMessage(error.response.data?.message || "Something went wrong!");
        setTimeout(() => {
          // setErrorMessage();
        }, 2000);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const { errors } = validateFields(
      { ...state, mainIncoming: state.mainIncoming.toString() },
      getFormSchema(state.deviceType?.label)
    );

    if (errors) {
      console.log({ errors, state });
      return setError(errors);
    }

    const body = {
      name: state.name,
      site: state.site,
      deviceType: state.deviceType?.value,
      energySource: state.energySource,
      mainIncoming: state.mainIncoming,
    };

    if (state.deviceType?.label === "Virtual") {
      Object.assign(body, {
        devices: state.devices,
      });
    } else {
      Object.assign(body, {
        serial: state.serial,
        apiKey: state.apiKey,
      });
    }

    if (mode === "create") {
      createDevice(body);
    } else if (mode === "edit") {
      updateDevice(body);
    }
  };
  //htmlFor site location dropdown
  const getSiteLocations = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/site-location/my-site`, {
      withCredentials: true,
    });
    if (data) {
      setSiteLocations(data?.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
    }
  };

  const handleChangeDeviceType = (_value, option) => {
    setState({ ...state, deviceType: option });
  };

  const getDevices = async () => {
    setDeviceLoading(true);
    axios
      .get(`/device`, { withCredentials: true })
      .then((response) => {
        setDeviceLoading(false);
        setDevices(response.data?.results);
      })
      .catch(() => setDeviceLoading);
  };

  useEffect(() => {
    if (state.site && state.deviceType?.label === "Virtual") {
      getDevices();
    }
  }, [state.site, state.deviceType]);

  useEffect(() => {
    getSiteLocations();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (mode !== "create") {
  //     setState({ ...state, site: siteLocations[0]?._id });
  //   }
  //   // eslint-disable-next-line
  // }, [siteLocations]);

  //htmlFor device type dropdown
  const getDeviceTypes = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/device-type`, { withCredentials: true });
    if (response) {
      setDeviceTypes(response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
    }
  };

  useEffect(() => {
    document.title = `SEDA - ${mode === "edit" ? "Update Device" : "Add New Device"}`;
    getDeviceTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === "edit" && data) {
      setState({
        name: data.name,
        deviceType: { label: data.deviceType?.name, value: data.deviceType?._id },
        site: data.site?._id,
        devices: data?.devices?.map((d) => d._id),
        energySource: data.energySource,
        mainIncoming: data.mainIncoming,
        serial: data.serial,
        apiKey: data.apiKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, data]);

  useEffect(() => {
    if (state.deviceType) {
      if (state.deviceType?.label === "Virtual") {
        setState({
          ...state,
          devices: state.devices?.length > 0 ? state.devices : [],
        });
      } else {
        setState({
          ...state,
          energySource: state.energySource,
          mainIncoming: state.mainIncoming,
          serial: state.serial,
          apiKey: state.apiKey,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.deviceType]);

  const columns = [
    {
      name: "No.",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
      center: true,
    },
    {
      name: "Device Name",
      selector: (row) => row?.name,
    },
    state?.deviceType?.label !== "Virtual" && {
      name: "Installer",
      selector: (row) => row?.site?.installer[0]?.name,
      cell: (row) => (
        <div>
          {Array.isArray(row?.site?.installer) && row?.site?.installer?.map((installer) => <Tag>{installer.name}</Tag>)}
        </div>
      ),
      center: true,
    },
    // {
    //   name: "Installer",
    //   selector: (row) => row?.site?.installer[0]?.name,
    //   cell: (row) => (<div>
    //     {
    //       Array.isArray(row?.site?.installer) &&
    //       row?.site?.installer?.map((installer) => <Tag>{installer.name}</Tag>)
    //     }
    //   </div>),
    //   center: true,
    // },
    {
      name: "Date Installed",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="actions checkbox">
            <input
              className="form-check-input border border-success "
              type="checkbox"
              value={row._id}
              id="flexCheckDefault"
              onChange={onSelectDevice}
              checked={state.devices?.includes(row._id)}
            />
          </div>
        </div>
      ),
      grow: 2,
      center: true,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="card p-3 mb-3">
        <div className="row">
          <div className="col-md-6">
            <h4 className="mb-4">{mode === "edit" ? "Update Device" : "Add New Device"}</h4>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center">
          {isLoading && <Spinner animation="border" variant="dark" />}
        </div> */}
        {SuccessMessage && (
          <div className="alert alert-success" role="alert">
            {SuccessMessage}{" "}
          </div>
        )}
        {ErrorMessage && (
          <div className="alert alert-danger" role="alert">
            {ErrorMessage}
          </div>
        )}
        <form onSubmit={submitHandler}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="deviceType" className="form-label">
                <Tooltip
                  placement="topLeft"
                  title="Refer to the type of device to be connected, whether it is from the meter installed on-site, a portable device, a virtual device, or a test device."
                >
                  {/* <Button>Top</Button> */}
                  Device Type
                </Tooltip>
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
                {/* <span className="circle-info">
                  <TiInfoLarge />
                </span> */}
              </label>

              <Select
                style={{ width: "100%", color: "red" }}
                size="large"
                id="admin"
                name="admin"
                value={state.deviceType}
                onChange={handleChangeDeviceType}
                placeholder="Select device type"
                options={[...deviceTypes.map((item) => ({ label: item.name, value: item._id }))]}
                required
              />
              {error.deviceType && (
                <div id="" className="text-danger">
                  {error.deviceType}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="site" className="form-label">
                <Tooltip placement="topLeft" title="Refer to the site that is assigned.">
                  Site Location
                </Tooltip>

                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <select
                className="form-select"
                id="site"
                name="site"
                value={site}
                onChange={onInputChange}
                aria-label="Select a site location"
                // required
              >
                <option value="0" disabled selected>
                  Select Site Location
                </option>
                {siteLocations?.length > 0 &&
                  siteLocations.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
              {error.site && (
                <div id="" className="text-danger">
                  {error.site}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="name" className="form-label">
                <Tooltip
                  placement="topLeft"
                  title="Create a unique name for the device and ensure it is not used for any other registration."
                >
                  Device Name
                </Tooltip>

                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter full name"
                // required
              />
              {error.name && (
                <div id="" className="text-danger">
                  {error.name}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="energySource" className="form-label">
                Energy Source
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <select
                className="form-select"
                id="energySource"
                name="energySource"
                value={energySource}
                onChange={onInputChange}
                aria-label="Select Energy Source"
                // required
              >
                <option value={""} disabled>
                  Select Device Type
                </option>
                <option value={"Electrical Power"}>Electrical Power</option>
                <option value={"Solar Thermal"}>Solar Thermal</option>
              </select>
              {error.energySource && (
                <div id="" className="text-danger">
                  {error.energySource}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="mainIncoming" className="form-label">
                <Tooltip
                  placement="topLeft"
                  title="Refer to the device to be installed at the main incoming or at the distribution line."
                >
                  Main Incoming
                </Tooltip>
                <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                  *
                </span>
              </label>
              <select
                className="form-select"
                id="mainIncoming"
                name="mainIncoming"
                value={mainIncoming}
                onChange={onInputChange}
                aria-label="Select Device Type"
                // required
              >
                <option value={""}>Select Incoming Status</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              {error.mainIncoming && (
                <div id="" className="text-danger">
                  {error.mainIncoming}
                </div>
              )}
            </div>
          </div>

          {deviceType?.label === "Virtual" ? (
            <>
              <div>
                <h6 className="py-2">Assign Multiple Devices to Virtual Device</h6>
              </div>
              <Spin spinning={deviceLoading}>
                <DataTable
                  columns={columns}
                  data={devices}
                  persistTableHead={true}
                  pagination
                  striped
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 50]}
                />
              </Spin>
            </>
          ) : (
            <>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="serial" className="form-label">
                    Serial No
                    <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="serial"
                    value={serial}
                    onChange={onInputChange}
                    className="form-control"
                    id="serial"
                    placeholder="Enter device serial"
                    // required
                  />
                  {error.serial && (
                    <div id="" className="text-danger">
                      {error.serial}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="apiKey" className="form-label">
                    Device API Key
                    <span className="text-danger" style={{ fontFamily: "monospace", marginLeft: 3 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="apiKey"
                    value={apiKey}
                    onChange={onInputChange}
                    className="form-control"
                    id="apiKey"
                    placeholder="Enter device API key"
                    // required
                  />
                  {/* <div id="emailHelp" className="form-text">
                    API Key is mandatory to get device data from the gateway
                  </div> */}

                  {error.apiKey && (
                    <div id="" className="text-danger">
                      {error.apiKey}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="float-end">
            <button type="submit" className="btn btn-success me-2">
              {mode === "edit" ? "Update Device" : "Create Device"}
            </button>
            <Link to="/devices" className="btn btn-secondary">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </Spin>
  );
};

export default DeviceForm;
