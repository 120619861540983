/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { FiTrash, FiEye, FiEdit, FiPaperclip } from "react-icons/fi";
import { ThemeContext } from "../../App.js";
import { Form, Input, Spin } from "antd";
import { Col, Row } from "react-bootstrap";

const { Search } = Input;

const SiteLocationsList = () => {
  let { isDark } = useContext(ThemeContext);

  const [siteLocations, setSiteLocations] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [totalRow, setTotalRow] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const getSiteLocations = async (userDetails) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
        params: { ...filter, page, limit },
      });
      if (data?.success) {
        setSiteLocations(data?.data?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
        setTotalRow(data?.meta?.total);
        setLimit(data?.meta?.limit);
        setPage(data?.meta?.page);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    document.title = "SEDA - All Site Locations";
    getSiteLocations(userDetails);
  }, [userDetails, filter, page, limit]);
  const columns = [
    {
      name: "#",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    // {
    //   name: "Building Name",
    //   selector: (row) => row.buildingName,
    // },
    {
      name: "Contact Person",
      selector: (row) => row.contactPersonName,
    },
    {
      name: "Phone",
      cell: (row) => <>+6{row.contactPersonPhone}</>,
      selector: (row) => row.contactPersonPhone,
      width: "140px",
    },

    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "150px",
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link to={`/site-locations/details/` + row._id} className="btn btn-success me-1">
                      <FiEye title="View" />
                    </Link>
                    <Link to={`/site-locations/edit/` + row._id} className="btn btn-info me-1">
                      <FiEdit title="Edit" />
                    </Link>
                    <Link to={`/site-locations/document/` + row._id} className="btn btn-warning me-1">
                      <FiPaperclip title="Documents" />
                    </Link>
                    <button className="btn btn-danger" onClick={() => deleteSiteLocation(row._id)}>
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "installer":
                return (
                  <div className="actions">
                    <Link to={`/site-location/` + row._id} className="btn btn-success me-1">
                      <FiEye title="View" />
                    </Link>
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link to={`/site-locations/details/` + row._id} className="btn btn-success me-1">
                      <FiEye title="View" />
                    </Link>
                    <Link to={`/site-locations/edit/` + row._id} className="btn btn-info me-1">
                      <FiEdit title="Edit" />
                    </Link>
                    <Link to={`/site-locations/document/` + row._id} className="btn btn-warning me-1">
                      <FiPaperclip title="Documents" />
                    </Link>
                    <button className="btn btn-danger" onClick={() => deleteSiteLocation(row._id)}>
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "user":
                return <div className="actions"></div>;
              case "public":
                return <div className="actions"></div>;
              default:
                return;
            }
          })()}
        </div>
      ),
      // grow: 2,
      width: "220px",
      center: "yes",
    },
  ];
  const deleteSiteLocation = async (siteLocationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this site location?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/site-location/` + siteLocationId, { withCredentials: true }).then((res) => {
          getSiteLocations(userDetails);
          Swal.fire({
            title: "Done!",
            text: "Site location Successfully Deleted",
            icon: "success",
            timer: 2000,
            button: false,
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  return (
    <div className="card p-3 mb-3">
      <h4>All Site Locations</h4>

      <div className="filter mt-3">
        <Form layout="vertical" onValuesChange={(value) => setFilter((prev) => ({ ...prev, ...value }))}>
          <Row lg={4} md={2} sm={1}>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col>
              <Search
                placeholder="Search"
                onSearch={(value) => setFilter((prev) => ({ ...prev, search: value }))}
                allowClear
              />
            </Col>
          </Row>
        </Form>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          columns={columns}
          data={siteLocations}
          noDataComponent={isLoading ? "Loading" : "No record found."}
          pagination
          striped={!isDark}
          theme={isDark ? "dark" : "light "}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 50]}
          paginationTotalRows={totalRow}
          onChangePage={(data) => {
            setPage(data);
          }}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
        />
      </Spin>
    </div>
  );
};

export default SiteLocationsList;
